import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookSlider from "../../components/bookSlider"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
//import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import AstroBook from "../../../static/images/commonImg/astrobook.svg"

const generalData = {
    title: "はじめてつくるNuxtサイト",
    updated: "2022/11/08",
    interview1: "",
    urlToApp: "https://nuxt-book-beginner.vercel.app",
    urlToCode1: "https://github.com/mod728/nuxtbook-portfolio-website",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0B5N8QJ2Y",
    reviewLink2: ""
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "はじめてつくるAstroサイト", img: AstroBook, url: "https://www.amazon.co.jp/dp/B0DMLKMTZX", description: "採用件数が右肩上がりのフレームワークAstro<br/><br/>HTML／CSSの知識だけで、簡単に高機能なウェブサイトを開発できるのが特徴です。<br/><br/>最新のバージョン5を使った入門書。"},
    {newRelease: "", title: "はじめてつくるVueアプリ", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: "「Reactの次はVueに挑戦したい」<br/><br/>という人向けのVue開発入門書です。<br/><br/>天気アプリを最新のバージョンのVue（3.2）を使って開発します。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるNext.jsサイト", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", description: "2時間でNext.jsの使い方がわかる入門書。<br/><br/>Next.jsバージョン13で新たに導入されたAppフォルダの使ってウェブサイトを開発する入門書。"},
    {newRelease: "", title: "はじめてつくるバックエンドサーバー基礎編（Node.js & Express）", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: "近年のウェブ開発では、フロントエンドとバックエンドの連携がますます深まっています。<br/><br/>フロントエンド開発者が知っておくべきバックエンドまわりの仕組みを、Node.jsとExpressを使ってわかりやすく紹介します。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", description: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "Volar", url: "https://marketplace.visualstudio.com/items?itemName=Vue.volar"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "マークダウンファイルのデータ", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/chapter3/content/blog"},
            {name: "ブログ記事用画像", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/chapter3/public"},
            {name: "その他の画像", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/chapter3/assets/images"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "CSSファイル", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/chapter3/assets/styles"},
            {name: "第3章完了時のソースコード", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "Vercel", url: "https://www.vercel.com"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "ファビコン画像", url: "https://github.com/mod728/nuxtbook-portfolio-website/tree/master/public"},
            {name: "第5章完了時のソースコード", url: "https://github.com/mod728/nuxtbook-portfolio-website"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
]

const NuxtBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                 <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
            <BookSlider booksDataForSlider={booksDataForSlider}/>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
    </div>
)

export default NuxtBookLinks